<template>
  <div class="classDetail">
    <div class="sub-title">教室环境</div>
    <div class="item-table">
      <div class="table-title">
        <img class="table-icon" src="../../assets/image/icon_dw_bs.png" alt="" />{{ classItem.building + classItem.floor
            + classItem.classroom
        }}
      </div>
      <div class="loading" v-if="classLoading">
        <van-loading size="24px" vertical color="#fff" type="spinner">加载中...</van-loading>
      </div>
      <div v-else class="table-content">
        <div class="cell-list">
          <div class="cell-list-listItem" v-for="item, index in classDetail" :key="item.key"
            :style="{ 'border-left': (index % 3 != 0) ? '1px solid rgba(255, 255, 255, 0.34)' : '' }">
            <div class="cell-list-listItem-value-group">
              <div class="cell-list-listItem-value">{{ item.value }}</div>
              <div class="cell-list-listItem-unit">{{ item.value != ''||item.value=='0' ? item.unit : '-' }}</div>
            </div>
            <div class="cell-list-listItem-name">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-title">教室设备</div>
    <div class="loading" v-if="deviceLoading">
      <van-loading size="24px" vertical type="spinner">加载中...</van-loading>
    </div>
    <div class="device-list" v-else>
      <div class="device-list-title">
        <div class="device-listTitle-id">设备号</div>
        <div class="device-listTitle-type">设备类型</div>
        <div class="device-listTitle-status">设备状态</div>
      </div>
      <div class="device-list-content">
        <div class="device-listItem"
          :style="{ borderBottom: index != deviceList.length - 1 ? '1px solid #E4E4E4' : 'none' }"
          v-for="item, index in deviceList" :key="index">
          <div class="device-listItem-value device-id">{{ item.nodeEui }}</div>
          <div class="device-listItem-value device-type">{{ item.product.name }}</div>
          <div class="device-listItem-value device-status" :style="{
            background: item.connected == 1 ? '#53C26C' : '#CCCCCC'
          }">{{ item.connected == 1 ? '在线' : '离线' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDdc, getDevice } from '../../http/control/control';

export default {
  data() {
    return {
      classDetail: [],
      deviceList: [],
      classItem: '',
      classLoading: false,
      deviceLoading: false
    }
  },
  methods: {
    async getDdc(id) {
      this.classLoading = true
      const res = await getDdc({
        id: id
      })
      res.data.forEach(item => {
        if (item.value && ['DL'].includes(item.key)) {
          item.value = parseFloat(item.value.toFixed(0))
        }
      });
      this.classDetail = res.data
      this.classLoading = false
    },
    async getDevice(id) {
      this.deviceLoading = true
      const res = await getDevice({
        id: id
      })
      this.deviceList = res.data
      this.deviceLoading = false
    }
  },
  computed: {
    toFix: function (number) {
      return Number(20).toFixed(0)
    }
  },
  created() {
    this.loading = true
    this.classItem = this.$route.query
    this.getDdc(this.classItem.id)
    this.getDevice(this.classItem.id)

  }
}
</script>
<style scoped lang="less">
.loading {
  padding-bottom: 20px;
}

.classDetail {
  padding: 0 12px;
}

.sub-title {
  margin: 16px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 16px;
}

.item-table {
  background: linear-gradient(35deg, #557AF2 0%, #38ADFF 100%);
  border-radius: 12px;
  color: #fff;

}

.table-content {
  /* padding: 0 8px; */
}

.table-title {
  margin-left: 20px;
  height: 53px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  display: flex;
  align-items: center;
}

.cell-list {
  display: flex;
  flex-wrap: wrap;

}

.cell-list-row {
  display: flex;
  margin-bottom: 24px;
}

.cell-list-listItem {
  padding-left: 16px;
  width: 33%;
  box-sizing: border-box;
  margin-bottom: 24px;
}

.cell-list-listItem-name {
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
  margin-top: 8px;
}

.cell-list-listItem-value-group {
  display: flex;
  align-items: flex-end;
}

.cell-list-listItem-value {

  font-size: 20px;
  font-family: Avenir-Medium, Avenir;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 24px;
  margin-right: 2px;
}

.table-icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.device-list {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 0 20px;
  margin-bottom: 20px;

  .device-list-title {
    display: flex;
    height: 53px;
    align-items: center;
    font-weight: 500;
    color: #666666;
    border-bottom: 1px solid #E4E4E4;
    font-size: 14px;

    .device-listTitle-id {
      width: 50%;
    }

    .device-listTitle-type {
      width: 30%;
    }

    .device-listTitle-status {
      width: 20%;
      text-align: center;
    }
  }

  .device-listItem {
    display: flex;
    height: 50px;
    align-items: center;

  }

  .device-listItem-value {
    font-size: 14px;
    font-weight: 500;
  }

  .device-id {
    width: 50%;
  }

  .device-type {
    width: 30%;
  }

  .device-status {
    width: 48px;
    height: 20px;
    border-radius: 10px;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 20px;
    text-align: center;
    margin: 0 auto;
  }
}
</style>
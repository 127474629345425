import http from '../request'
export async function sceneClassroomList(data) {
  return http.get('/mobile/scene/classroom', {
    params: data
  })
}
export async function scene(data) {
  return http.post('/mobile/scene', data)
}
export async function getScene(data) {
  return http.get('/mobile/scene', {
    params: data
  })
}
export async function getDdc(data) {
  return http.get(`/mobile/ddc/${data.id}`, )
}
export async function getDevice(data) {
  return http.get(`/mobile/ddc/${data.id}/device/`, )
}
/* export async function getControl(data) {
  return http.get(`/mobile/device/${data.id}/control`, )
} */